const routes = [
    {
        path: '/company/supplier_world/search/supplier',
        name: 'CompanySWSearchSuppliers',
        component: () => import('../../views/company/supplier_world/search/Suppliers.vue')
    },
    {
        path: '/company/supplier_world/search/supplier/:id/details',
        name: 'CompanySWSearchSupplier',
        component: () => import('../../views/company/supplier_world/search/Supplier.vue')
    },
    {
        path: '/company/supplier_world/search/director_shareholder',
        name: 'CompanySWSearchDirectorShareholder',
        component: () => import('../../views/company/supplier_world/search/Director_Shareholder.vue')
    },
]

export default routes;
const routes = [
  {
    path: '/contract-management/dashboard',
    name: 'ContractManagementDashboard',
    component: () => import('../views/contract_management/dashboard/Dashboard.vue')
  },
  {
    path: '/contract-management/create/upload',
    name: 'ContractManagementCreateUpload',
    component: () => import('../views/contract_management/create/upload/UploadContract.vue')
  },
  {
    path: '/contract-management/create/author',
    name: 'ContractManagementCreateAuthor',
    component: () => import('../views/contract_management/create/author/AuthorContract.vue')
  },
  {
    path: '/contract-management/create/ai',
    name: 'ContractManagementCreateAI',
    component: () => import('../views/contract_management/create/ai/AiContract.vue')
  },
  {
    path: '/contract-management/edit',
    name: 'ContractManagementEdit',
    component: () => import('../views/contract_management/edit/DraftContracts.vue')
  },
  {
    path: '/contract-management/edit/:contractId/view',
    name: 'ContractManagementEditView',
    component: () => import('../views/contract_management/edit/Contract.vue')
  },
  {
    path: '/contract-management/edit/:contractId/update',
    name: 'ContractManagementEditUpdate',
    component: () => import('../views/contract_management/edit/EditContract.vue')
  },
  {
    path: '/contract-management/approval',
    name: 'ContractManagementApproval',
    component: () => import('../views/contract_management/approval/Contracts.vue')
  },
  {
    path: '/contract-management/approval/:contractId/view',
    name: 'ContractManagementApprovalView',
    component: () => import('../views/contract_management/approval/ApproveContract.vue')
  },
  {
    path: '/contract-management/signature',
    name: 'ContractManagementSignature',
    component: () => import('../views/contract_management/signature/Contracts.vue')
  },
  {
    path: '/contract-management/signature/:contractId/upload',
    name: 'ContractManagementSignatureUpload',
    component: () => import('../views/contract_management/signature/UploadSignedContract.vue')
  },
  {
    path: '/contract-management/archival/upload',
    name: 'ContractManagementArchivalContracts',
    component: () => import('../views/contract_management/archive/UploadContract.vue')
  },
  {
    path: '/contract-management/archival/view',
    name: 'ContractManagementArchivalCategories',
    component: () => import('../views/contract_management/archive/Categories.vue')
  },
  {
    path: '/contract-management/archival/view/:categoryId/contracts',
    name: 'ContractManagementArchivalCategories',
    component: () => import('../views/contract_management/archive/Contracts.vue')
  },
  {
    path: '/contract-management/archival/view/:contractId',
    name: 'ContractManagementArchivalContracts',
    component: () => import('../views/contract_management/archive/Contract.vue')
  },
  {
    path: '/contract-management/reports',
    name: 'ContractManagementReports',
    component: () => import('../views/contract_management/reports/ReportList.vue')
  },
  {
    path: '/contract-management/report/progress/:task_id',
    name: 'ContractManagementReportProgress',
    component: () => import('../views/contract_management/reports/ReportGenerationProgress.vue')
  },
  {
      path: '/contract-management/sections',
      name: 'ContractManagementTemplatesSections',
      component: () => import('../views/contract_management/sections/Sections.vue')
    },
    {
      path: '/contract-management/sections/:sectionId/view',
      name: 'ContractManagementTemplatesSection',
      component: () => import('../views/contract_management/sections/Section.vue')
    },
    {
      path: '/contract-management/sections/create',
      name: 'ContractManagementTemplatesSectionsCreate',
      component: () => import('../views/contract_management/sections/CreateSection.vue')
    },
    {
      path: '/contract-management/sections/:sectionId/update',
      name: 'ContractManagementTemplatesSectionsUpdate',
      component: () => import('../views/contract_management/sections/UpdateSection.vue')
    },
    {
      path: '/contract-management/templates',
      name: 'ContractManagementTemplatesTemplates',
      component: () => import('../views/contract_management/templates/Templates.vue')
    },
    {
      path: '/contract-management/templates/:templateId/view',
      name: 'ContractManagementTemplatesTemplate',
      component: () => import('../views/contract_management/templates/Template.vue')
    },
    {
      path: '/contract-management/templates/:templateId/update',
      name: 'ContractManagementTemplatesTemplatesUpdate',
      component: () => import('../views/contract_management/templates/UpdateTemplate.vue')
    },
    {
      path: '/contract-management/templates/create',
      name: 'ContractManagementTemplatesTemplatesCreate',
      component: () => import('../views/contract_management/templates/CreateTemplate.vue')
    },
  {
    path: '/contract-management/categories',
    name: 'ContractManagementCategories',
    component: () => import('../views/contract_management/categories/Categories.vue')
  },
  {
    path: '/contract-management/categories/create',
    name: 'ContractManagementCategoryiesCreate',
    component: () => import('../views/contract_management/categories/CreateCategory.vue')
  },
  {
    path: '/contract-management/categories/edit/:categoryId',
    name: 'ContractManagementCategoryiesEdit',
    component: () => import('../views/contract_management/categories/EditCategory.vue')
  },
]

export default routes;
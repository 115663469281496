const routes = [
    {
        path: '/company/approvers/supplier_contracts',
        name: 'CompanySystemSupplierContractApprovers',
        component: () => import('../../views/company/system_management/approvers/SupplierContractApprovers.vue')
    },
    {
        path: '/company/branches',
        name: 'CompanyBranches',
        component: () => import('../../views/company/system_management/branches/Branches.vue')
    },
    {
        path: '/company/branches/create',
        name: 'CompanyBranchesCreate',
        component: () => import('../../views/company/system_management/branches/CreateBranch.vue')
    },
    {
        path: '/company/blacklisted/suppliers',
        name: 'CompanyBlackListedSuppliers',
        component: () => import('../../views/company/system_management/suppliers/BlackListedSuppliers.vue')
    },
]

export default routes;